import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ClinicalHistoryItem } from 'src/app/shared/interfaces/clinical-history-item';

@Component({
  selector: 'app-summary-order-concept-value',
  templateUrl: './summary-order-concept-value.component.html',
  styleUrls: ['./summary-order-concept-value.component.css']
})
export class SummaryOrderConceptValueComponent implements OnInit {

  @Input() item: ClinicalHistoryItem;
  observaciones: string;
  posologia?: string;
  
  constructor() { }

  ngOnInit(): void {
    this.posologia = this.item.valorConcepto;
    const json = this.item.jsonHistoriaClinicaActual || this.item.jsonOrdenMedicaDetalle
    if (json) {
      this.procesarJson(json);
    }
  }

  private procesarJson(json: string): void {
    const obj = JSON.parse(json);
    if (obj?.Comentarios) {
      this.posologia = this.posologia?.replace(obj.Comentarios, '').trim();
      this.posologia = (this.posologia || '').length <= 1 ? this.posologia?.replace('.',''): this.posologia;
      this.observaciones = obj.Comentarios;
    }
  }

}
